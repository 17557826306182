.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%; /* Make the form take full width on small screens */
  max-width: 400px; /* Limit the form width on larger screens */
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.contact-form > h2 {
  font-size: 1.5rem;
}

.contact-form > h4 {
  font-size: 1.2rem;
}

.contact-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.contact-form textarea {
  resize: vertical;
  height: 100px;
}

.contact-form button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.contact-wrapper {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
  gap: 20px; /* Adjust spacing between image and form */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.contact-wrapper img {
  max-width: 100%;
  height: auto; /* Make image responsive */
}

.heading-section-1 {
  padding-top: 20px;
  text-align: center;
  color: rgb(238, 38, 38);
  font-size: 3rem; /* Adjust the size as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-form {
    max-width: 350px; 
  }

  .heading-section-1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .contact-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }

  .contact-form form{
    width: 10%;
    padding: 15px;
  }

  .heading-section-1 {
    font-size: 2rem;
  }
}

@media (max-width: 425px) {
  .contact-wrapper {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .contact-form {
    width: 80%; /* Full width on mobile */
    max-width: none; /* Remove max-width limitation */
    padding-left: 30px;
    left: 50px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
  }

  .contact-form button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .heading-section-1 {
    font-size: 1.5rem;
  }

  .btn-con {
    font-size: 16px;
    padding: 8px 16px;
    width: 100px;
    height: 40px;
  }
}
