/* Prevent horizontal scroll on the body and html */
body, html {
  overflow-x: hidden;
}

/* Main app container styling */
.app {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw; /* Ensures full viewport width */
  background-color: black;
  overflow-x: hidden; /* Ensures no horizontal scrolling */
}

/* Videos container styling */
.app__videos {
  position: relative;
  height: 100vh;
  width: 100vw; /* Ensures container takes full viewport width */
  max-width: 450px;
  max-height: 1200px;
  overflow-y: scroll; /* Scrolls only vertically */
  overflow-x: hidden; /* Prevents horizontal scroll */
  scroll-snap-type: y mandatory;
}

/* Hide scrollbar for all browsers */
.app__videos::-webkit-scrollbar {
  display: none;
}
.app__videos {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
