.terms-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding-top: 140px;
    padding-bottom: 100px;
}

.terms-section {
    margin-bottom: 30px;
    background: white;
    border-radius: 8px;
    padding: 15px;
}

.terms-title {
    font-size: 24px;
    color: #333;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.terms-text {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
}

.terms-contact-title,
.terms-contact-details {
    font-size: 18px;
    color: #555;
    margin: 10px 0;
}
