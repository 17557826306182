/* Hide scrollbar for Chrome, Safari, and Edge */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  .scrollbar-hide {
    scrollbar-width: none; /* For Firefox */
  }
  
  /* Prevent scrollbar from showing up */
  .overflow-x-auto {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

 
  @media (max-width: 426px) {
    .explore-mob-wrper {
      margin-top: -70px;
    }
  }