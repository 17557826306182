*,
*:after,
*:before {
  box-sizing: border-box;
}

.img-glry {
  max-width: 100%;
  height: 650px;
  object-fit: cover;
  position: relative;
}

.gallery-wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.gallery-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.5s ease-out;
  transform-style: preserve-3d;
}

.gallery-box {
  width: 530px;
  position: absolute;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
}

.gallery-box a img {
  margin-top: 10%;
  margin-bottom: 10%;
}


@media (max-width: 475px) {
  .arrow-wrapper {
    position: absolute;
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    bottom: 0px;
  }
  .gallery-center {
    position: absolute;
    top: 20%;
    left: 50%;
    transition: all 0.5s ease-out;
    transform-style: preserve-3d;
  }
  .gallery-box {
    width: 500px;
    height: 100px;
    position: absolute;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
  }
  .img-glry {
    min-width: 100%;
    height: 600px;
    /* object-fit: cover; */
  }
}

/* Arrow Wrapper */
.arrow-wrapper {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

/* Arrow Styles */
.arrow-glry {
  font-size: 2rem;
  color: #000000;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-left-glry {
  position: absolute;
  left: -10px;
  margin-top: -235px;
}

.arrow-right-glry {
  position: absolute;
  right: -10px;
  margin-top: -235px;
}