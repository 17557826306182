.videoHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width:60%;
    color:white;
    padding: 10px;
    /*  always use with  position absolute,
    width 100% so that the content is properly aligned*/
}
