.wrpper-inter {
  position: relative;
}

.hero-section-left-1 {
  position: relative;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-img {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 480px) {

  .all-packages-description {
    font-size: 0.9rem;     
  }
  .hero-section-left-1 {
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}


.hero-section-right-1{
  position: absolute;
  left: 70vw;
}

.hero-section-h2{
  text-align: center;
}


.hero-button {
  font-family: inherit;
  font-size: 20px;
  background: royalblue;
  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}

.hero-button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.hero-button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.hero-button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.hero-button:hover span {
  transform: translateX(5em);
}

.hero-button:active {
  transform: scale(0.95);
}

.hero-lottie{
  width: 40%;
  height: 70%;
}

.lottie-wr{
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.cards-container-1 {
  margin: 20px; 
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

.ind-h{
  font-size: 2.5rem;
  color: #2C3E50;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  position: relative; 
}

.ind-h::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;  
  width: 10%;  
  height: 4px;  
  background-color: yellow;  
  border-radius: 2px; 
}

.ind-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ind-img {
  width: 80%; 
  height: 60vh;
  object-fit: cover; 
  border-radius: 10px; 
}

.all-packages-heading::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;  
  width: 10%;  
  height: 4px;  
  background-color: yellow;  
  border-radius: 2px; 
}

.all-packages-description {
  font-size: 1.2rem;     
  color: #000;        
  text-align: center;    
  line-height: 1.5;      
  font-family: 'Arial', sans-serif; 
}

@media (max-width: 1024px) {
  .contact-form{
    /* width: 80vw; */
    margin-right: 80px;
  }
}