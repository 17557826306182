.privacy-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding-top: 140px;
}

.privacy-section {
    margin-bottom: 30px;
    background: white;
    border-radius: 8px;
    padding: 15px;
}

.privacy-title {
    font-size: 24px;
    color: #333;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.privacy-text {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
}

.privacy-contact-title,
.privacy-contact-details {
    font-size: 18px;
    color: #555;
    margin: 10px 0;
}

@media (max-width: 1024px) {
    .privacy-title {
        font-size: 22px;
    }
    .privacy-text {
        font-size: 15px;
    }
    .privacy-contact-title,
    .privacy-contact-details {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .privacy-container {
        padding: 20px;
        padding-top: 100px;
    }
    .privacy-title {
        font-size: 20px;
    }
    .privacy-text {
        font-size: 14px;
    }
    .privacy-contact-title,
    .privacy-contact-details {
        font-size: 15px;
    }
}

@media (max-width: 576px) {
    .privacy-container {
        padding: 15px;
        padding-top: 100px;
    }
    .privacy-title {
        font-size: 18px;
    }
    .privacy-text {
        font-size: 13px;
    }
    .privacy-contact-title,
    .privacy-contact-details {
        font-size: 14px;
    }
}
