.container {
  width: 80%;
  margin: 0 auto; /* Center the container */
}

.visiting-card {
  display: flex;
  flex-direction: column;
  height: 100%; /* Make sure cards grow to fill the container */
}

.food-card-img {
  width: 100%;
  height: 48%; /* Adjust height as needed */
  object-fit: cover;
}

.visiting-card-content {
  flex-grow: 1; /* Allow content to fill the remaining space */
  padding: 1rem; /* Padding inside the content area */
}

.location-info {
  display: flex;
  align-items: center;
  margin-top: 0.5rem; /* Space above location info */
}
