.social-icons {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  width: 50px;
  transition: width 0.5s ease;
  overflow: hidden;
  background-color: #fff;
  margin-left: auto;
  padding: 10px;
}

.icon:hover {
  width: 145px;
}

.icon svg {
  font-size: 30px;
  color: #fff;
}

.icon span {
  position: absolute;
  left: 60px;
  opacity: 0;
  white-space: nowrap;
  font-size: 16px;
  color: #333;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  font-family: Arial, sans-serif;
  line-height: 1.2;
  padding: 5px 10px;
}

.icon:hover span {
  opacity: 1;
  background-color: #fff;
  color: #000;
}

.facebook {
  background-color: #3b5998;
}

.whatsapp {
  background-color: #25D366;
}

.instagram {
  background: linear-gradient(to top right, #f9ce34, #ee2a7b, #6228d7);
}

.youtube {
  background-color: #FF0000;
}

/* Media query for screens smaller than 425px */
@media (max-width: 425px) {
  .social-icons {
    top: 35%; /* Adjust positioning if needed */
    z-index: 10;
  }

  .icon {
    width: 30px; /* Reduce icon container size */
    height: 30px;
    padding: 4px;
  }

  .icon svg {
    font-size: 20px; /* Decrease icon size */
  }

  .icon span {
    left: 30px;
    font-size: 10px; /* Decrease font size */
    padding: 3px 8px;
  }

  .icon:hover {
    width: 90px; /* Adjust hover width for smaller screens */
  }
}
