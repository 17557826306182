.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-left: 20%;
  padding-right: 20%;
}
.abcd {
  z-index: 999;
}
.form {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 2em;
  background-color: #171717;
  border-radius: 25px;
}

#heading {
  text-align: center;
  margin: 1em 0;
  color: rgb(255, 255, 255);
  font-size: 1.2em;
}

.field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 25px;
  padding: 0.6em;
  border: none;
  outline: none;
  color: white;
  background-color: #171717;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}
.input-icon {
  height: 1.3em;
  width: 1.3em;
  fill: white;
}

.input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #d3d3d3;
}

.btn {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2.5em;
}

.button1,
.button2 {
  padding: 0.5em 1em;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #252525;
  color: white;
  transition: background-color 0.4s ease-in-out;
}

.button1:hover,
.button2:hover {
  background-color: black;
}

.button2 {
  margin-left: 0.5em;
}
.error-message {
  color: red;
}
