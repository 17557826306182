

.gallery-heading {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 130px; /* Ensure this spacing works well with your layout */
}

.gallery-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.gallery-item {
    overflow: hidden;
    position: relative; /* Make sure the item is positioned relative for absolute children */
    border-radius: 8px;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease; /* Smooth zoom-in effect */
}

.gallery-item:hover img {
    transform: scale(1.1); /* Zoom-in effect */
}

@media screen and (max-width: 1200px) {
    .gallery-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 992px) {
    .gallery-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .gallery-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 576px) {
    .gallery-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
