/* Blog container with image background */
.blog-container {
  position: relative;
  padding-top: 100px;
}

.blog-img {
  width: 100vw;
  height: 80vh;
  object-fit: cover;
}

.blog-heading-bottom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  text-align: center;
  font-size: 2vw; /* Responsive font size based on viewport width */
  max-width: 90%;
}

/* Grid container for blog cards */
.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns per row */
  gap: 20px;
  margin: 20px auto;
  max-width: 1200px;
  padding: 20px 10px;
}

.card {
  overflow: hidden;
  text-align: center;
  margin: 10px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

/* Image inside each card */
.card-image {
  width: 100%;
  height: 200px; /* Adjusted for responsiveness */
  object-fit: cover;
}

.card-content {
  padding: 10px 15px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.blog-h1, .blog-h2, .blog-h3 {
  font-size: 1.3rem; /* Default for larger screens */
}

/* Responsive Text Sizes */
@media (max-width: 1024px) {
  .blog-h1, .blog-h2, .blog-h3 {
    font-size: 1.2rem; /* Slightly smaller for tablets */
  }
  .blog-heading-bottom {
    font-size: 1.8vw;
  }
}

@media (max-width: 768px) {
  .blog-h1, .blog-h2, .blog-h3 {
    font-size: 1.1rem; /* Adjust for smaller tablets */
  }
  .blog-heading-bottom {
    font-size: 2.5vw; /* Increase slightly for smaller screens */
  }
}

@media (max-width: 480px) {
  .blog-h1, .blog-h2, .blog-h3 {
    font-size: 1rem; /* Further decrease for mobile */
  }
  .blog-heading-bottom {
    font-size: 3vw; /* Larger on small screens */
  }
}

@media (max-width: 426px) {
  .blog-h1, .blog-h2, .blog-h3 {
    font-size: 1rem; /* Further decrease for mobile */
  }
  .blog-heading-bottom {
    font-size: 3vw; /* Larger on small screens */
  }
  .blog-container {
    padding-top: 60px; /* Removes the top padding */
  }
}

@media (max-width: 360px) {
  .blog-h1, .blog-h2, .blog-h3 {
    font-size: 0.8rem; /* Minimum font size for very small screens */
  }
  .blog-heading-bottom {
    font-size: 4vw; /* Increase to ensure readability */
  }
}
