.cancellation-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.cancel-container {
  padding-top: 180px;
  padding-bottom: 100px;
}

.cancellation-header {
  margin-bottom: 30px;
}

.cancellation-title {
  font-size: 28px;
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  text-align: center;
}

.cancellation-intro {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.cancellation-intro>span {
  color: #000;
  font-weight: bold;
}

.table-container {
  margin-bottom: 40px;
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.package-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.policy-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
  font-weight: bold;
}

.cancellation-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.cancellation-table th,
.cancellation-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.cancellation-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.cancellation-table td {
  font-size: 14px;
  color: #444;
}

.cancellation-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.cancellation-table tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .cancel-container {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  .cancellation-container {
    padding: 15px;
  }
  .cancellation-title {
    font-size: 24px;
  }
  .package-title {
    font-size: 20px;
  }
  .policy-text,
  .cancellation-intro {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .cancel-container {
    padding-top: 100px;
    padding-bottom: 60px;
  }
  .cancellation-title {
    font-size: 22px;
  }
  .package-title {
    font-size: 18px;
  }
  .policy-text,
  .cancellation-intro {
    font-size: 13px;
  }
}

@media (max-width: 640px) {
  
  .cancellation-title {
    font-size: 20px;
  }
  .package-title {
    font-size: 16px;
  }
  .policy-text,
  .cancellation-intro {
    font-size: 12px;
  }
}

@media (max-width: 480px) {

  .cancellation-title {
    font-size: 18px;
  }
  .package-title {
    font-size: 14px;
  }
  .policy-text,
  .cancellation-intro {
    font-size: 11px;
  }
  .cancellation-table th,
  .cancellation-table td {
    font-size: 12px;
    padding: 8px;
  }
}

@media (max-width: 360px) {

  .cancellation-title {
    font-size: 16px;
  }
  .package-title {
    font-size: 12px;
  }
  .policy-text,
  .cancellation-intro {
    font-size: 10px;
  }
  .cancellation-table th,
  .cancellation-table td {
    font-size: 10px;
    padding: 6px;
  }
}

