.videoCard {
    position: relative;
    border: 2px solid white;
    width: 100%;
    height: 100%;
    scroll-snap-align:start;
}

.videoCard__player {
    object-fit: fill;
    width: 100%;
    height: 100%;
} 
