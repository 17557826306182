.navbar-1 {
  background-color: #FFF;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 5px;
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 45px;
  margin-top: 60px;
  position: fixed;
}

.nav-links-1 {
  list-style: none;
  display: flex;
  padding: 0;
  font-size: 1rem; /* Default font size */
  text-align: center;
  justify-content: center;

}

.nav-item-1 {
  position: relative;
  color: #000;
  padding: 10px 5px;
  cursor: pointer;
  display: inline-flex; /* Ensures horizontal alignment of text and icon */
  align-items: center; /* Vertically centers the content */
  padding-right: 40px;
}

.dropdown-icon {
  margin-left: 5px; /* Space between text and icon */
  font-size: 0.8em; /* Adjust icon size if needed */
  transition: transform 0.7s ease; /* Smooth transition for rotation */
}

.nav-item-1:hover .dropdown-icon {
  transform: rotate(180deg); /* Rotates icon by 90 degrees on hover */
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 50%; /* Centers dropdown horizontally */
  transform: translateX(-50%); /* Centers the dropdown relative to the nav item */
  background-color: #fff;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none; /* Initially hidden */
  min-width: 150px;
  z-index: 10;
}

.dropdown-item {
  padding: 10px 20px;
  color: #000;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #a6d5f9;
}

.nav-item-1:hover .dropdown {
  display: block; /* Show dropdown on hover */
}

/* Media queries to adjust font size */
@media (max-width: 1166px) {
  .nav-links-1 {
    font-size: 0.9rem; /* Slightly smaller font for screens <= 1024px */
  }
}

@media (max-width: 1079px) {
  .nav-links-1 {
    font-size: 0.85rem; /* Further reduction for screens <= 900px */
  }
}

@media (max-width: 1042px) {
  .nav-links-1 {
    font-size: 0.8rem; /* Further reduction for screens <= 900px */
  }
  .nav-item-1 {
    padding-right: 30px; /* Further reduction for screens <= 900px */
  }
}

@media (max-width: 889px) {
  .nav-links-1 {
    font-size: 0.7rem; /* Further reduction for screens <= 900px */
  }
  .nav-item-1 {
    padding-right: 30px; /* Further reduction for screens <= 900px */
  }
}

@media (max-width: 796px) {
  .nav-links-1 {
    font-size: 0.65rem; /* Further reduction for screens <= 900px */
  }
  .nav-item-1 {
    padding-right: 25px; /* Further reduction for screens <= 900px */
  }
}

@media (max-width: 756px) {
  .nav-links-1 {
    font-size: 0.6rem; /* Further reduction for screens <= 900px */
  }
  .nav-item-1 {
    padding-right: 25px; /* Further reduction for screens <= 900px */
  }
}

@media (max-width: 690px) {
  .nav-links-1 {
    font-size: 0.55rem; /* Further reduction for screens <= 900px */
  }
  .nav-item-1 {
    padding-right: 25px; /* Further reduction for screens <= 900px */
  }
  .navbar-1{
      height: 40px;
  }
}

@media (max-width: 656px) {
  .navbar-1 {
    display: none; /* Hide dropdown after 768px */
  }
}

