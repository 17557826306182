.instagram-gradient {
    background: linear-gradient(to top right, #f9ce34, #ee2a7b, #6228d7);
    border-radius: 25%;
    display: flex;
    align-items: center;
    padding-left: 2px ;
    padding-right: 2px;
    justify-content: center;
    color: white;
  }
  
  .facebook-gradient{

  }