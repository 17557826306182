*, *:after, *:before {
  box-sizing: border-box;
}

.img-glry {
  max-width: 100%;
  height: 650px;
  object-fit: cover;
  position: relative;
}

.gallery-wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
}

.gallery-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.5s ease-out;
  transform-style: preserve-3d;
}

.gallery-box {
  width: 530px;
  position: absolute;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
}

.gallery-box a img {
  margin-top: 10%;
  margin-bottom: 10%;
}

/* Updated Arrow Wrapper */
.arrow-wrapper {
  position: absolute;  /* Changed from relative to absolute */
  width: 100%;
}

/* Updated Arrow Styles */
.arrow-glry {
  position: fixed;
  font-size: 2rem;
  color: #000000;
  cursor: pointer;
  border-radius: 20px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  z-index: 10;  /* Added to ensure arrows stay above images */
  top: calc(50% + 150px);
}

.arrow-left-glry {
  left: 10px;
}

.arrow-right-glry {
  right: 10px;
}

@media (max-width: 475px) {
  .gallery-center {
    position: absolute;
    top: 20%;
    left: 50%;
    transition: all 0.5s ease-out;
    transform-style: preserve-3d;
  }

  .gallery-box {
    width: 500px;
    height: 100px;
    position: absolute;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
  }

  .img-glry {
    min-width: 100%;
    height: 600px;
  }

  .arrow-glry {
    top: calc(50% + 120px);
  }
}